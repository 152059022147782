import React from 'react';
import HeaderText from "./HeaderText";
import Item from "./Item";
import './index.css';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl, locale}) => (
    <div className="stronger">
        <HeaderText locale={locale} />
        <div className="stronger-items">
            <Item
                logo="/static/svg/stronger-shield.svg"
                header={<FormattedMessage id="stronger.left.header" />}
            >
                <div 
                    className="stronger-item-left-text"
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'stronger.left'})}}
                />
            </Item>
            <Item
                logo="/static/svg/stronger-breath.svg"
                header={<FormattedMessage id="stronger.center.header" />}
            >
                <div className="stronger-item-center-text">
                    <FormattedMessage id="stronger.center" />
                </div>
            </Item>
            <Item
                logo="/static/svg/stronger-pill.svg"
                header={<FormattedMessage id="stronger.right.header" />}
            >
                <div className="stronger-item-right-text" >
                    <FormattedMessage id="stronger.right" />
                </div>
            </Item>
        </div>
    </div>
));