import React from 'react';
import BuyNowButton from '../../common/buy-now-button'
import './index.css';
import {injectIntl} from "react-intl";

export default injectIntl(({intl}) => (
    <div className="middle-buy-now">
        <div 
            className="middle-buy-now-header"
            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'middle.buy.now.header'})}}
        />
        <picture>
            <source 
                media="(max-width: 375px)"
                srcSet="/static/images/middle-buy-now-bottle--mobile.png"
            />
            <img
                src="/static/images/middle-buy-now-bottle.png"
                alt=""
                className="middle-buy-now__bottle"
            />
        </picture>
        <span className="middle-buy-now__button">
            <BuyNowButton large/>
        </span>
    </div>
));
