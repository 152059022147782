import React from 'react';
import './index.css';
import cx from 'classnames';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl, locale}) => {
    const classes = cx({
        'virus-spread-arrows-text' : true,
        'virus-spread-arrows-text__center' : true,
        'virus-spread-arrows-text__center--ru': locale === 'ru'
    });
    return (
        <div className="virus-spread">
            <div className="virus-spread__header">
                <FormattedMessage id="virus.spread.header" />
            </div>
            <div className="virus-spread-content">
                <picture>
                    <source 
                        media="(max-width: 900px)"
                        srcSet="/static/svg/virus-spread-img--mobile.svg"
                    />
                    <img
                        src="/static/svg/virus-spread-img.svg"
                        alt=""
                        className="virus-spread--img"
                    />
                </picture>
                <div className="virus-spread-arrows">
                    <div className="virus-spread-arrows__img">
                        <picture>
                            <source 
                                media="(max-width: 900px)"
                                srcSet="/static/svg/virus-spread-arrows--mobile.svg"
                            />
                            <img
                                src="/static/svg/virus-spread-arrows.svg"
                                alt=""
                                className="virus-spread-arrows__img--img"
                            />
                        </picture>
                    </div>
                    <div className="virus-spread-arrows-text virus-spread-arrows-text__left">
                        <FormattedMessage id="virus.spread.left" />
                    </div>
                    <div className={classes}>
                        <FormattedMessage id="virus.spread.center" />
                    </div>
                    <div className="virus-spread-arrows-text virus-spread-arrows-text__right">
                        <FormattedMessage id="virus.spread.right" />
                    </div>
                </div>
            </div>
        </div>
    )
});
