import React from 'react';
import './index.css';
import cx from 'classnames';
import {injectIntl} from "react-intl";

export default injectIntl(({intl, locale}) => {
    const classes = cx({
        'vitamin-illness__image-text' : true,
        'vitamin-illness__image-text--ru': locale === 'ru'
    });
    return (
        <div className="vitamin-illness">
            <div 
                className="vitamin-illness-header__text"
                dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.illness.header'})}}
            />
            <div className="vitamin-illness-header__image">
                <picture>
                    <source 
                        media="(max-width: 375px)"
                        srcSet="/static/images/vitamin-illness-banner--mobile.png"
                    />
                    <img
                        src="/static/images/vitamin-illness-banner.png"
                        alt=""
                        className="vitamin-illness-header__image--img"
                    />
                </picture>
            </div>
            <div className="vitamin-illness-content">
                <div className="vitamin-illness-content__item">
                    <img
                        src="/static/svg/vitamin-illness-point.svg"
                    />
                    <div 
                        className="vitamin-illness-content__text"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.illness.first.point'})}}
                    />
                </div>
                <div className="vitamin-illness-content__item">
                    <img
                        src="/static/svg/vitamin-illness-point.svg"
                    />
                    <div 
                        className="vitamin-illness-content__text"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.illness.second.point'})}}
                    />
                </div>
                <div className="vitamin-illness-content__item">
                    <img
                        src="/static/svg/vitamin-illness-point.svg"
                    />
                    <div 
                        className="vitamin-illness-content__text"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.illness.third.point'})}}
                    />
                </div>
            </div>
            <div className="vitamin-illness__image">
                <div className="vitamin-illness__image--arrow">
                    <a href="http://www.vitamiinD.ee" target="_blank">
                        <picture>
                            <source 
                                media="(max-width: 375px)"
                                srcSet="static/svg/vitamin-illness-arrow--mobile.svg"
                            />
                            <img
                                src="/static/svg/vitamin-illness-arrow.svg"
                                alt=""
                            />
                        </picture>
                    </a>
                </div>
                <div className={classes}>
                    <a 
                        href="http://www.vitamiinD.ee" 
                        target="_blank"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.illness.arrow'})}}
                    />
                </div>
            </div>
        </div>
    )
});
