import React from 'react';
import BuyNowButton from '../../common/buy-now-button';
import './index.css';
import cx from 'classnames';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl, locale}) => {
    const classesLeft = cx({
        'why-choose__text' : true,
        'why-choose__top-left' : true,
        'why-choose__text--ru': locale === 'ru',
        'why-choose__top-left--ru': locale === 'ru'
    });
    const classesRight = cx({
        'why-choose__text' : true,
        'why-choose__top-right' : true,
        'why-choose__text--ru': locale === 'ru',
        'why-choose__top-right--ru': locale === 'ru'
    });
    const classesBottom = cx({
        'why-choose__text' : true,
        'why-choose__bottom-left' : true,
        'why-choose__text--ru': locale === 'ru',
        'why-choose__bottom-left--ru': locale === 'ru'
    });
    return (
        <div id="why-choose"  className="why-choose">
            <div className="why-choose__header">
                <FormattedMessage id="why.choose.header" />
            </div>
            <div className="why-choose__content">
                <picture>
                    <source 
                        media="(max-width: 375px)"
                        srcSet="/static/images/why-choose--mobile.png"
                    />
                    <source 
                        media="(max-width: 550px)"
                        srcSet="/static/images/why-choose--550.png"
                    />
                    <img
                        src="/static/images/why-choose.png"
                        alt=""
                        className="why-choose__content--image"
                    />
                </picture>
                <picture>
                    <source 
                        media="(max-width: 375px)"
                        srcSet="/static/svg/why-choose-clouds--mobile.svg"
                    />
                    <source 
                        media="(max-width: 550px)"
                        srcSet="/static/svg/why-choose-clouds--550.svg"
                    />
                    <img
                        src="/static/svg/why-choose-clouds.svg"
                        alt=""
                        className="why-choose__content--clouds"
                    />
                </picture>
                <div 
                    className={classesLeft}
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'why.choose.item.1'})}}
                />
                <div 
                    className={classesRight}
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'why.choose.item.2'})}}
                />
                <div 
                    className={classesBottom}
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'why.choose.item.3'})}}
                />
                <div className="why-choose__bottle-div">
                    <picture>
                        <source 
                            media="(max-width: 375px)"
                            srcSet="/static/images/why-choose-bottle--mobile.png"
                        />
                        <img
                            src="/static/images/why-choose-bottle.png"
                            alt=""
                            className="why-choose__bottle"
                        />
                    </picture>
                    <span className="why-choose__button">
                        <BuyNowButton large />
                    </span>
                </div>
            </div>
        </div>
    );
})
