import React from 'react';
import FooterText from "./FooterText";
import Item from './Item';
import './index.css';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl})=> (
    <div className="stay-healthy" id="stay-healthy">
        <div className="stay-healthy__header">
            <FormattedMessage id="stay.healthy.header" />
        </div>
        <div className="stay-healthy__items">
            <Item
                url="/static/images/stay-healthy-sleep.png"
                text={<FormattedMessage id="stay.healthy.sleep" />}
            />
            <Item
                url="/static/images/stay-healthy-active.png"
                text={<FormattedMessage id="stay.healthy.active" />}
            />
            <Item
                url="/static/images/stay-healthy-stress.png"
                text={<FormattedMessage id="stay.healthy.stress" />}
            />
            <Item
                large
                url="/static/images/stay-healthy-vitamin.png"
                text={<span dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'stay.healthy.vitamin'})}}/>}
            />
            <Item
                url="/static/images/stay-healthy-drink.png"
                text={<FormattedMessage id="stay.healthy.drink" />}
            />
            <Item
                url="/static/images/stay-healthy-food.png"
                text={<FormattedMessage id="stay.healthy.food" />}
            />
        </div>
        <FooterText />
    </div>
));
