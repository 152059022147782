import React from 'react';
import './index.css';
import cx from 'classnames';
import {FormattedMessage} from "react-intl";

export default ({locale}) => {
    const classes = cx({
        'top-banner__text' : true,
        'top-banner__text--ru': locale === 'ru'
    });
    return (
        <div className="top-banner">
            <picture>
                <source 
                    media="(max-width: 375px)"
                    srcSet="/static/images/375.png"
                />
                <source 
                    media="(max-width: 900px)"
                    srcSet="/static/images/top-banner--mobile.png"
                />
                <img
                    src="/static/images/top-banner.png"
                    alt=""
                    className="top-banner__image"
                />
            </picture>
            <picture>
                <source 
                    media="(max-width: 375px)"
                    srcSet="/static/images/top-banner-bottle--375.png"
                />
                <source 
                    media="(max-width: 900px)"
                    srcSet="/static/images/top-banner-bottle--mobile.png"
                />
                <img
                    src="/static/images/top-banner-bottle.png"
                    alt=""
                    className="top-banner__image-bottles"
                />
            </picture>
            <div className={classes}>
                <FormattedMessage id="top.banner.text" />
            </div>
        </div>
    );
}
