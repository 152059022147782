import React from 'react';
import './index.css';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl}) => (
    <div className="zinc" id="zinc">
        <div className="zinc-banner">
            <picture>
                <source 
                    media="(max-width: 375px)"
                    srcSet="/static/images/zinc-bg--mobile.png"
                />
                <img
                    src="/static/images/zinc-bg.png"
                    alt=""
                    className="zinc-banner__image"
                />
            </picture>
        </div>
        <div className="zinc-header__text">
            <FormattedMessage id="zinc.header" />
        </div>
        <div className="zinc-content">
            <div className="zinc-content__item">
                <img
                    src="/static/svg/zinc-point.svg"
                />
                <div 
                    className="zinc-content__text"
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'zinc.item.1'})}}
                />
            </div>
            <div className="zinc-content__item">
                <img
                    src="/static/svg/zinc-point.svg"
                />
                <div 
                    className="zinc-content__text"
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'zinc.item.2'})}}
                />
            </div>
            <div className="zinc-content__item">
                <img
                    src="/static/svg/zinc-point.svg"
                />
                <div 
                    className="zinc-content__text"
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'zinc.item.3'})}}
                />
            </div>
        </div>
    </div>
));
