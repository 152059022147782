import React from 'react';
import './FooterText.css';
import {injectIntl} from "react-intl";

export default injectIntl(({intl}) => (
    <div className="prevention-footer">
        <div 
            className="prevention-footer__text"
            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'prevention.footer'})}}
        />
    </div>
));

