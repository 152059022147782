import React from 'react';
import './index.css';
import FooterText from './FooterText';
import {FormattedMessage} from "react-intl";

export default () => (
    <div className="prevention" id="prevention">
        <div className="prevention-header__text">
            <FormattedMessage id="prevention.header" />
        </div>
        <div className="prevention-header">
            <picture>
                <source 
                    media="(max-width: 375px)"
                    srcSet="/static/images/prevention-banner--mobile.png"
                />
                <img
                    src="/static/images/prevention-banner.png"
                    alt=""
                    className="prevention-header__image"
                />
            </picture>
        </div>
        <div className="prevention-content">
            <div className="prevention-content__item">
                <img
                    src="/static/svg/prevention-point.svg"
                />
                <div className="prevention-content__text">
                    <FormattedMessage id="prevention.first.point" />
                </div>
            </div>
            <div className="prevention-content__item">
                <img
                    src="/static/svg/prevention-point.svg"
                />
                <div className="prevention-content__text">
                    <FormattedMessage id="prevention.second.point" />
                </div>
            </div>
            <div className="prevention-content__item">
                <img
                    src="/static/svg/prevention-point.svg"
                />
                <div className="prevention-content__text">
                    <FormattedMessage id="prevention.third.point" />
                </div>
            </div>
        </div>
        <FooterText />
    </div>
);
