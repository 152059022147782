import React from 'react';
import './index.css';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl}) => (
    <div id="bioflavonoids"  className="bioflavonoids">
        <div className="bioflavonoids-header__text">
            <FormattedMessage id="bioflavonoids.header" />
        </div>
            <picture>
                <source 
                    media="(max-width: 375px)"
                    srcSet="/static/images/bioflavonoids-banner--mobile.png"
                />
                <img
                    src="/static/images/bioflavonoids-banner.png"
                    alt=""
                    className="bioflavonoids-header__image"
                />
            </picture>
        <div className="bioflavonoids-content">
            <div className="bioflavonoids-content__item">
                <picture>
                    <source 
                        media="(max-width: 375px)"
                        srcSet="/static/svg/bioflavonoids-point--mobile.svg"
                    />
                    <img
                        src="/static/svg/bioflavonoids-point.svg"
                        alt=""
                    />
                </picture>
                <div 
                    className="bioflavonoids-content__text"
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'bioflavonoids.first.point'})}}
                />
            </div>
            <div className="bioflavonoids-content__item">
                <picture>
                    <source 
                        media="(max-width: 375px)"
                        srcSet="/static/svg/bioflavonoids-point--mobile.svg"
                    />
                    <img
                        src="/static/svg/bioflavonoids-point.svg"
                        alt=""
                    />
                </picture>
                <div 
                    className="bioflavonoids-content__text"
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'bioflavonoids.second.point'})}}
                />
            </div>
            <div className="bioflavonoids-content__item">
                <picture>
                    <source 
                        media="(max-width: 375px)"
                        srcSet="/static/svg/bioflavonoids-point--mobile.svg"
                    />
                    <img
                        src="/static/svg/bioflavonoids-point.svg"
                        alt=""
                    />
                </picture>
                <div className="bioflavonoids-content__text">
                    <FormattedMessage id="bioflavonoids.third.point" />
                </div>
            </div>
        </div>
    </div>
));
