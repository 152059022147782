import React from 'react';
import './index.css';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl}) => (
    <div className="virus-estonia" id="virus-estonia">
        <div className="virus-estonia-header__text">
            <FormattedMessage id="virus.estonia.header" />
        </div>
        <div className="virus-estonia-header__image">
            <picture>
                <source 
                    media="(max-width: 375px)"
                    srcSet="/static/images/virus-estonia-banner--mobile.png"
                />
                <img
                    src="/static/images/virus-estonia-banner.png"
                    alt=""
                    className="virus-estonia-header__image--img"
                />
            </picture>
        </div>
        <div className="virus-estonia-content">
            <div className="virus-estonia-content__item">
                <img
                    src="/static/svg/virus-estonia-point.svg"
                />
                <div 
                    className="virus-estonia-content__text"
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'virus.estonia.first.point'})}}
                />
            </div>
            <div className="virus-estonia-content__item">
                <img
                    src="/static/svg/virus-estonia-point.svg"
                />
                <div 
                    className="virus-estonia-content__text"
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'virus.estonia.second.point'})}}
                />
            </div>
            <div className="virus-estonia-content__item">
                <img
                    src="/static/svg/virus-estonia-point.svg"
                />
                <div 
                    className="virus-estonia-content__text"
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'virus.estonia.third.point'})}}
                />
            </div>
        </div>
    </div>
));
