import React from 'react';
import './HeaderBottom.css';

export default () => {
    return (
        <div className="header-bottom">
            <img
                src="/static/svg/logo.svg"
                alt="pro expert vitamin D"
                className="header-bottom__logo"
            />
            <img
                src="/static/svg/logo-background.svg"
                alt=""
                className="header-bottom__logo-background"
            />
            <img
                className="header-bottom__text"
                src="/static/svg/header-text.svg"
            />
            <div className="header-bottom__sun-logo-wrapper">
                <picture>
                    <source 
                        media="(max-width: 375px)"
                        srcSet="/static/images/top-sun-logo--375.png"
                    />
                    <source 
                        media="(max-width: 900px)"
                        srcSet="/static/images/top-sun-logo--mobile.png"
                    />
                    <img
                        src="/static/images/top-sun-logo.png"
                        alt=""
                        className="header-bottom__sun-logo"
                    />
                </picture>
            </div>
        </div>
    );
};